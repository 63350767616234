(function ($) {
  $(document).ready(function () {

    var $current_page = 1;

    function reactiveFilters(filters) {
      for (var i = 0; i < filters.length; i++) {

        $(".documents__filter[data-status='" + filters[i] + "']").trigger('click');
      }
    }

    function retriveFilters() {
      var filters = Array();
      $('.documents__filter--active').each(function () {
        filters.push($(this).data('status'));
      });
      return filters;
    }

    function ajaxCall(call) {
      var $filters = $('#archive-docs__form');
      var $serializedDatas = $filters.serializeArray();
      var $jsonDatas = JSON.stringify($serializedDatas);

      filters = retriveFilters();

      $.ajax({
        url: ajaxurl,
        method: 'POST',
        data: {
          form: $jsonDatas,
          searchValue: $searchValue,
          page_num: $current_page,
          action: call
        },
        success: function (response) {
          $('.archive-ajax-initial-content').remove();
          $('.ajax-return').html(response);
          resetDocs(); //defined in main.js
          reactiveFilters(filters);
          countDocsActive(); //defined in main.js
        }
      });
    }

    $(document).on('click', '.page-numbers', function (e) {
      e.preventDefault();

      if ($(this).hasClass('next')) {
        window.$current_page = window.$current_page + 1;
      } else if ($(this).hasClass('prev')) {
        window.$current_page = window.$current_page - 1;
      } else {
        window.$current_page = parseInt($(this).text());
      }

      ajaxCall('save');
    });

    $('.filters-detect-change').change(function () {
      window.$current_page = 1;

      // for the offres d'emplois
      ajaxCall('save');
    });

    $('.archive-documents__filters input[type=checkbox]').change(function () {
      $(this).parents('label').toggleClass('label-checked');
    });

    $('.archive-docs__filters-left #keyword-form').submit(function (e) {
      e.preventDefault();
      $('.archive-docs__filters-left-holder').removeClass('archive-docs__filters-left-holder--active');
      $('.archive-docs__filters-left').removeClass('archive-docs__filters-left--active');
      $searchValue = $(this).find('#search-value').val();
      ajaxCall('save2');
    });

    $('#reset-docs_reset_left_filters').click(function () {
      if (isIE()) {
        $('#filters-form')[0].reset();
      }
    });

    $('#filters-form').on('reset', function (event) {
      window.$current_page = 1;

      setTimeout(function () {
        ajaxCall('save2');
      });
    });

    $(document).on('click', '.js-document-help', function (e) {
      e.preventDefault();
      $this = $(this);
      $parent = $this.parents('.document');

      $parent.toggleClass('document__help-active');
    });
  });
})(jQuery);
